header,
footer {
    height: 100px;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    color: #ffffff;
}
/* .background {
    background-repeat: no-repeat;
} */
.count-down-background {
    background-color: #212121a4;
    /* background-image: url("https://static.vecteezy.com/system/resources/previews/007/633/071/original/cartoon-colorful-panorama-of-spring-summer-beautiful-nature-green-grasslands-meadow-with-mountains-on-horizon-background-summer-mountain-landscape-dawn-over-the-valley-illustration-free-vector.jpg"); */
    background-size: 100% 100%;
}
.back-color {
    background-color: #212121a4;
}
.text-size {
    font-size: 6em;
}

@media (max-width: 425.99px) {
    .text-size {
        font-size: 3em;
    }
    h1 {
        font-size: medium;
    }
}
.img-size {
    max-width: 600px !important;
    max-height: auto !important;
}

.counter-page {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("../img/NewHomeMain.png");
    background-size: cover;
    background-position: center;
}
